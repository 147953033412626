<template>
  <div class="detail">
    <div class="header flex flex-cross-start">
      <div class="flex-auto " style="text-align:center">
        <div class="model">{{refund.orderType?refund.orderType.title:"共享空调租赁"}}</div>
        <div style="font-weight: bold;font-size: 18px;color: black;">{{refund.refundMoney?refund.refundMoney:refund.applyMoney}}</div>
        <div :style="colorRefund(refund)">{{refund.refundStatus?refund.refundStatus.title:''}}</div>
      </div>
    </div>
    <div class="block">
      <div class="row">
        <span class="label">订单编号：</span>
        <span>{{refund.orderNo || '无'}}</span>
      </div>
      <div class="row">
        <span class="label">创建时间：</span>
        <span>{{refund.orderCreateTime || ''}}</span>
      </div>
      <div class="row">
        <span class="label">支付时间：</span>
        <span>{{refund.payStartTime || ''}}</span>
      </div>
      <div class="row">
        <span class="label">支付方式：</span>
        <span>{{refund.payType?refund.payType.title:''}}</span>
      </div>
    </div>
    <div class="block" v-if="refund.refundType">
      <div class="row">
        <span class="label">退款方式：</span>
        <span>{{refund.refundType?refund.refundType.title:''}}</span>
      </div>
      <div class="row" v-if="refund.memo">
        <span class="label">退款备注：</span>
        <span>{{refund.memo?refund.memo:''}}</span>
      </div>
    </div>
    <div class="block">
      <div class="row">
        <span class="label">设备SN：</span>
        <span>{{refund.sn || '无'}}</span>
      </div>
      <div class="row">
        <span class="label">设备位置：</span>
        <span>{{refund.address || ''}}</span>
      </div>
      <div class="row">
        <span class="label">授权时间：</span>
        <span>{{refund.startTime?refund.startTime.substring(0,10) : ''}} 至 {{refund.endTime?refund.endTime.substring(0,10) : ''}}</span>
      </div>
    </div>
    <div class="block">
      <div class="row">
        <span class="label">姓名：</span>
        <span>{{refund.userName || '无'}}</span>
      </div>
      <div class="row">
        <span class="label">学号：</span>
        <span>{{refund.userNumber || ''}}</span>
      </div>
      <div class="row">
        <span class="label">电话：</span>
        <span>{{refund.userPhone? refund.userPhone :''}}</span>
      </div>
    </div>
    <div class="div-button" v-if="refund.refundStatus&&refund.refundStatus.name==='PENDING'"  @click="gotoPage(id)">
      <button class="pay-button">办理退款</button>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['id'],
    data () {
      return {
        refund: {},
      }
    },
    created () {
      this.loadInfo()
    },
    methods: {
      colorRefund (value) {
        if (value.refundStatus && value.refundStatus.name === 'PENDING') {
          return 'color:#909399'
        } else if (value.refundStatus && value.refundStatus.name === 'REFUNDING') {
          return 'color:#db6127'
        } else if (value.refundStatus && value.refundStatus.name === 'DONE') {
          return 'color:#67c23a'
        } else if (value.refundStatus && value.refundStatus.name === 'REFUSE') {
          return 'color:#f56c6c'
        } else if (value.refundStatus && value.refundStatus.name === 'FAIL') {
          return 'color:#f56c6c'
        } else if (value.refundStatus && value.refundStatus.name === 'CANCEL') {
          return 'color:#e6a23c'
        } else {
          return 'color:#000000'
        }
      },
      loadInfo () {
        if (this.id) {
          this.$axios.get(`/h5/refund/${this.id}`).then(res => {
            this.refund = res
          })
        }
      },
      gotoPage (id) {
        this.$router.push({ name: 'refundBack', params: { id: id } })
      },
    }
  }
</script>

<style scoped lang="stylus">
.modify
    width:80rpx;
    height:48rpx;
    background:rgba(25,121,255,0.08);
    border-radius:24rpx;
    float:right;
    border-color: transparent;
    font(24,40,#1979FF)
.detail
  background #fff
  padding 32rpx
  height: 900px;
  .header
    padding-bottom 32rpx
    border-bottom 1rpx solid rgba(31,31,52,.3)
    font(28,44,$subtext)
    .portrait
      box(144,144)
      margin-right 32rpx
    .model
      font(32,48,$text)
  .block
    border-bottom 1rpx solid rgba(31,31,52,.3)
    padding 32rpx 0
    .block-title
      font(32,48,$text)
    .row
      margin: 16rpx 0
      font(28,44,$text)
      word-break: break-all
      .label
        display inline-block
        width 160rpx
        margin-right 32rpx
        color $subtext

.div-button{
  margin-top: 50px;
  text-align: center;
}
.pay-button{
  color: white;
  background-color: #169bd5;
  border: 1px solid #169bd5;
  border-radius:12px;
  font-size: 18px;
  height: 40px;
  width: 120px;
}
</style>
